import React, { createContext, useEffect, useState } from 'react';
import { useWallet } from 'use-wallet';
import TombFinance from '../../tomb-finance';
import config from '../../config';

export interface TombFinanceContext {
  tombFinance?: TombFinance;
}

export const Context = createContext<TombFinanceContext>({ tombFinance: null });

export const TombFinanceProvider: React.FC = ({ children }) => {
  const { ethereum, account } = useWallet();
  const [tombFinance, setTombFinance] = useState<TombFinance>();

  // (window as any).ethereum.on('chainChanged', (chainId: any) => {
  //   console.log('chainId in event = ', parseInt(chainId));
  //   refreshConfig(parseInt(chainId));
  // });

  // function refreshConfig(chainId: number) {
  //   console.log('[refreshConfig] chainId = ', chainId);
  // }

  useEffect(() => {
    if (!tombFinance) {
      const tomb = new TombFinance(config);
      if (account) {
        // wallet was unlocked at initialization
        tomb.unlockWallet(ethereum, account);
      }
      setTombFinance(tomb);
    } else if (account) {
      tombFinance.unlockWallet(ethereum, account);
    }
  }, [account, ethereum, tombFinance]);

  return <Context.Provider value={{ tombFinance }}>{children}</Context.Provider>;
};
