import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './assets/fonts/Satoshi/css/satoshi.css';
import App from './App';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to work offline and load faster, you can change
// unregist to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
