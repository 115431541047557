// import { ChainId } from '@pancakeswap-libs/sdk';
import { Configuration } from './tomb-finance/config';
import { BankInfo } from './tomb-finance';
import { ChainId } from '@uniswap/sdk';

const config =
  Number(process.env.REACT_APP_CHAIN_ID) === 8453
    ? {
        chainId: Number(8453) as ChainId,
        networkName: 'Base Mainnet',
        ftmscanUrl: 'https://basescan.org/',
        defaultProvider: 'https://base.publicnode.com',
        deployments: require('./tomb-finance/deployments/deployments.base.json'),
        externalTokens: {
          // GIGA - change the addresses below to the new contracts
          CHARGE: ['0xbdd273Afeeac06769CE69EDb84B0Ccc8B22b9159', 18],
          CSHARE: ['0xba53c0360BE3fF414Eddd2896052b0A8B35f132C', 18],
          TOSHBOND: ['0xd0b75D271E962Ad0e6Efd8d2EC5092e796fd6253', 18],
          BULLRUN: ['0x1A9132ee02d7E98e51b7389D2e7BB537184867Aa', 18],
          'CHARGE-ETH-LP': ['0xeeb8AE27fB239b886806E82bD34c04de6190B80C', 18],
          'CSHARE-ETH-LP': ['0x449aA25788b646dBBF357E8E9De712cB618238A4', 18],
          // No need to change these below
          ETH: ['0x4200000000000000000000000000000000000006', 18],
          WETH: ['0x4200000000000000000000000000000000000006', 18],
        },
      }
    : {
        chainId: Number(42161) as ChainId,
        networkName: 'Arbitrum',
        ftmscanUrl: 'https://arbiscan.io/',
        defaultProvider: 'https://arbitrum-one.publicnode.com',
        deployments: require('./tomb-finance/deployments/deployments.arb.json'),
        externalTokens: {
          // GIGA - change the addresses below to the new contracts
          CHARGE: ['0x2D9b56e4aFc3Fe76C91D555c3073eEe26Dc8c9DB', 18],
          CSHARE: ['0xe775d688A93ae99D2ED13d9f8237d3FE588bF532', 18],
          TOSHBOND: ['0xd0b75D271E962Ad0e6Efd8d2EC5092e796fd6253', 18],
          'CHARGE-ETH-LP': ['0xF1b2Cf86db6548093E60F97396EeEadaBBF3F887', 18],
          'CSHARE-ETH-LP': ['0xB569C039a95b0C59eD37D3D4413a0B447c7D12A9', 18],
          // No need to change these below
          ETH: ['0x82af49447d8a07e3bd95bd0d56f35241523fbab1', 18],
          WETH: ['0x82af49447d8a07e3bd95bd0d56f35241523fbab1', 18],
        },
      };

const configurations: { [env: string]: Configuration } = {
  production: {
    ...(config as any),
    baseLaunchDate: new Date('2022-05-14T18:00:00Z'),
    bondLaunchesAt: new Date('2022-05-15T09:00:00Z'),
    masonryLaunchesAt: new Date('2022-05-15T09:00:00Z'),
    refreshInterval: 10000,
  },
};

export const bankDefinitions: { [contractName: string]: BankInfo } = {
  /*
  Explanation:
  name: description of the card
  poolId: the poolId assigned in the contract
  sectionInUI: way to distinguish in which of the 3 pool groups it should be listed
        - 0 = Single asset stake pools
        - 1 = LP asset staking rewarding TOMB
        - 2 = LP asset staking rewarding TSHARE
  contract: the contract name which will be loaded from the deployment.environmnet.json
  depositTokenName : the name of the token to be deposited
  earnTokenName: the rewarded token
  finished: will disable the pool on the UI if set to true
  sort: the order of the pool
  */

  // GIGA: if we need to add/remove gen pools from the lists, you can add/remove/modify the ones below

  // ToshareGenesisRewardPool: {
  //   name: 'Earn CHARGE by CSHARE',
  //   poolId: 5,
  //   sectionInUI: 0,
  //   contract: 'TombshiGenesisRewardPool',
  //   depositTokenName: 'CSHARE',
  //   earnTokenName: 'CHARGE',
  //   finished: false,
  //   sort: 4,
  //   closedForStaking: false,
  // },
  CshareFarmRewardPool: {
    name: 'Earn CSHARE by CSHARE/ETH LP',
    poolId: 1,
    sectionInUI: 0,
    contract: 'CshareRewardPool',
    depositTokenName: 'CSHARE-ETH-LP',
    earnTokenName: 'CSHARE',
    finished: false,
    sort: 5,
    closedForStaking: false,
  },
  ChargeFarmRewardPool: {
    name: 'Earn CSHARE by CHARGE/ETH LP',
    poolId: 0,
    sectionInUI: 0,
    contract: 'CshareRewardPool',
    depositTokenName: 'CHARGE-ETH-LP',
    earnTokenName: 'CSHARE',
    finished: false,
    sort: 5,
    closedForStaking: false,
  },
  BullrunFarmRewardPool: {
    name: 'Earn CSHARE by BULLRUN',
    poolId: 2,
    sectionInUI: 0,
    contract: 'CshareRewardPool',
    depositTokenName: 'BULLRUN',
    earnTokenName: 'CSHARE',
    finished: false,
    sort: 5,
    closedForStaking: false,
  },
  // tombshitoshiGenesisRewardPool: {
  //   name: 'Earn CHARGE by CHARGE-ETH-LP',
  //   poolId: 0,
  //   sectionInUI: 0,
  //   contract: 'tombshitoshiGenesisRewardPool',
  //   depositTokenName: 'CHARGE-ETH-LP',
  //   earnTokenName: 'CHARGE',
  //   finished: false,
  //   sort: 5,
  //   closedForStaking: false,
  // },
  // toshareethGenesisRewardPool: {
  //   name: 'Earn CHARGE by CSHARE-ETH-LP',
  //   poolId: 0,
  //   sectionInUI: 0,
  //   contract: 'toshareethGenesisRewardPool',
  //   depositTokenName: 'CSHARE-ETH-LP',
  //   earnTokenName: 'CHARGE',
  //   finished: false,
  //   sort: 6,
  //   closedForStaking: false,
  // },
  // usdbCGenesisRewardPool: {
  //   name: 'Earn CHARGE by USDBC',
  //   poolId: 0,
  //   sectionInUI: 0,
  //   contract: 'usdbCGenesisRewardPool',
  //   depositTokenName: 'USDBC',
  //   earnTokenName: 'CHARGE',
  //   finished: false,
  //   sort: 7,
  //   closedForStaking: false,
  // },
  // axlUsdbCGenesisRewardPool: {
  //   name: 'Earn CHARGE by AXLUSDBC',
  //   poolId: 0,
  //   sectionInUI: 0,
  //   contract: 'axlUsdbCGenesisRewardPool',
  //   depositTokenName: 'AXLUSDBC',
  //   earnTokenName: 'CHARGE',
  //   finished: false,
  //   sort: 8,
  //   closedForStaking: false,
  // },

  // WlrsUsdcLPWShareRewardPool: {
  //   name: 'Earn CSHARE by CHARGE-USDC.e LP',
  //   poolId: 0,
  //   sectionInUI: 0,
  //   contract: 'WlrsUsdcLPWShareRewardPool',
  //   depositTokenName: 'CHARGE-USDC-LP',
  //   earnTokenName: 'CSHARE',
  //   finished: false,
  //   sort: 1,
  //   closedForStaking: false,
  // },
  // WShareUsdcLPWShareRewardPool: {
  //   name: 'Earn CSHARE by CSHARE-USDC.e LP',
  //   poolId: 1,
  //   sectionInUI: 2,
  //   contract: 'WShareUsdcLPWShareRewardPool',
  //   depositTokenName: 'CSHARE-USDC-LP',
  //   earnTokenName: 'CSHARE',
  //   finished: false,
  //   sort: 2,
  //   closedForStaking: false,
  // },
  // WlrsUSDibsLPWShareRewardPool: {
  //   name: 'Earn CSHARE by CHARGE-USDibs LP',
  //   poolId: 3,
  //   sectionInUI: 2,
  //   contract: 'WlrsUSDibsLPWShareRewardPool',
  //   depositTokenName: 'CHARGE-USDIBS-LP',
  //   earnTokenName: 'CSHARE',
  //   finished: false,
  //   sort: 3,
  //   closedForStaking: false,
  // },
  // NrwlYusdLPWShareRewardPool: {
  //   name: 'Earn CSHARE by NRWL-YUSD LP',
  //   poolId: 4,
  //   sectionInUI: 2,
  //   contract: 'NrwlYusdLPWShareRewardPool',
  //   depositTokenName: 'NRWL-YUSD-LP',
  //   earnTokenName: 'CSHARE',
  //   finished: false,
  //   sort: 4,
  //   closedForStaking: false,
  // },
  // WBondWShareRewardPool: {
  //   name: 'Earn CSHARE with TOSHBOND',
  //   poolId: 6,
  //   sectionInUI: 0,
  //   contract: 'WBondWShareRewardPool',
  //   depositTokenName: 'TOSHBOND',
  //   earnTokenName: 'CSHARE',
  //   finished: false,
  //   sort: 5,
  //   closedForStaking: false,
  // },
  // XWLRSShareRewardPool: {
  //   name: 'Earn CSHARE with XWLRS',
  //   poolId: 7,
  //   sectionInUI: 0,
  //   contract: 'XWLRSShareRewardPool',
  //   depositTokenName: 'XWLRS',
  //   earnTokenName: 'CSHARE',
  //   finished: false,
  //   sort: 6,
  //   closedForStaking: false,
  // },
  // WShareUSDCWLRSRewardPool: {
  //   name: 'Earn CHARGE with CSHARE-USDC-LP',
  //   poolId: 8,
  //   sectionInUI: 0,
  //   contract: 'WShareUSDCWLRSRewardPool',
  //   depositTokenName: 'CSHARE-USDC-LP',
  //   earnTokenName: 'CHARGE',
  //   finished: false,
  //   sort: 7,
  //   closedForStaking: false,
  // },
  /*WlrsUsdcLPRebate: {
    name: 'Bond CHARGE-USDC-LP for CHARGE',
    poolId: 100,
    sectionInUI: 3,
    contract: 'RebateTreasury',
    depositTokenName: 'CHARGE-USDC-LP',
    earnTokenName: 'CHARGE',
    finished: false,
    sort: 1,
    closedForStaking: false,
  },
  WShareUsdcJoeLPRebate: {
    name: 'Bond CSHARE-USDC-LP for CHARGE',
    poolId: 100,
    sectionInUI: 3,
    contract: 'RebateTreasury',
    depositTokenName: 'CSHARE-USDC-LP',
    earnTokenName: 'CHARGE',
    finished: false,
    sort: 2,
    closedForStaking: false,
  },*/
  // AvaxJoeLPRebate: {
  //   name: 'Bond AVAX for CHARGE',
  //   poolId: 100,
  //   sectionInUI: 3,
  //   contract: 'RebateTreasury',
  //   depositTokenName: 'WAVAX',
  //   earnTokenName: 'CHARGE',
  //   finished: false,
  //   sort: 3,
  //   closedForStaking: false,
  // },
  // UsdcJoeLPRebate: {
  //   name: 'Bond USDC.e for CHARGE',
  //   poolId: 100,
  //   sectionInUI: 3,
  //   contract: 'RebateTreasury',
  //   depositTokenName: 'USDC',
  //   earnTokenName: 'CHARGE',
  //   finished: false,
  //   sort: 4,
  //   closedForStaking: false,
  // },
  // PegLPNode: {
  //   name: 'Generate CHARGE-USDC LP with Nodes',
  //   poolId: 0,
  //   sectionInUI: 4,
  //   contract: 'PegLPNode',
  //   depositTokenName: 'CHARGE-USDC-LP',
  //   earnTokenName: 'CHARGE-USDC-LP',
  //   finished: false,
  //   sort: 6,
  //   closedForStaking: false,
  // },
  // ShareLPNode: {
  //   name: 'Generate CSHARE-USDC LP with Nodes',
  //   poolId: 0,
  //   sectionInUI: 4,
  //   contract: 'ShareLPNode',
  //   depositTokenName: 'CSHARE-USDC-LP',
  //   earnTokenName: 'CSHARE-USDC-LP',
  //   finished: false,
  //   sort: 6,
  //   closedForStaking: false,
  // },
  // LPWlrsNode: {
  //   name: 'Generate GRAPE-CHARGE LP with Nodes',
  //   poolId: 0,
  //   sectionInUI: 4,
  //   contract: 'LPWlrsNode',
  //   depositTokenName: 'GRAPE-CHARGE-LP',
  //   earnTokenName: 'GRAPE-CHARGE-LP',
  //   finished: false,
  //   sort: 2,
  //   closedForStaking: false,
  // },
  // LPNrwlNode: {
  //   name: 'Generate NRWL-YUSD LP with Nodes',
  //   poolId: 0,
  //   sectionInUI: 4,
  //   contract: 'LPNrwlNode',
  //   depositTokenName: 'NRWL-YUSD-LP',
  //   earnTokenName: 'NRWL-YUSD-LP',
  //   finished: false,
  //   sort: 2,
  //   closedForStaking: false,
  // },
  // NrwlYusdGenesisNrwlRewardPool: {
  //   name: 'Earn NRWL by NRWL-YUSD LP',
  //   poolId: 0,
  //   sectionInUI: 5,
  //   contract: 'NrwlYusdGenesisNrwlRewardPool',
  //   depositTokenName: 'NRWL-YUSD-LP',
  //   earnTokenName: 'NRWL',
  //   finished: false,
  //   sort: 1,
  //   closedForStaking: false,
  // },
  // WlrsUsdcGenesisNrwlRewardPool: {
  //   name: 'Earn NRWL by CHARGE-USDC.e LP',
  //   poolId: 0,
  //   sectionInUI: 5,
  //   contract: 'WlrsUsdcGenesisNrwlRewardPool',
  //   depositTokenName: 'CHARGE-USDC-LP',
  //   earnTokenName: 'NRWL',
  //   finished: false,
  //   sort: 2,
  //   closedForStaking: false,
  // },
  // WshareUsdcGenesisNrwlRewardPool: {
  //   name: 'Earn NRWL by CSHARE-USDC.e LP',
  //   poolId: 0,
  //   sectionInUI: 5,
  //   contract: 'WshareUsdcGenesisNrwlRewardPool',
  //   depositTokenName: 'CSHARE-USDC-LP',
  //   earnTokenName: 'NRWL',
  //   finished: false,
  //   sort: 3,
  //   closedForStaking: false,
  // },
  // WshareGenesisNrwlRewardPool: {
  //   name: 'Earn NRWL by CSHARE',
  //   poolId: 0,
  //   sectionInUI: 5,
  //   contract: 'WshareGenesisNrwlRewardPool',
  //   depositTokenName: 'CSHARE',
  //   earnTokenName: 'NRWL',
  //   finished: false,
  //   sort: 4,
  //   closedForStaking: false,
  // },
};

export default configurations[/*process.env.NODE_ENV || */ 'production'];
