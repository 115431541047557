export const TOMB_TICKER = 'CHARGE';
export const NRWL_TICKER = 'NRWL';
export const TSHARE_TICKER = 'CSHARE';
export const TBOND_TICKER = 'TOSHBOND';
export const FTM_TICKER = 'USDC';
export const WFTM_TICKER = 'USDC';
export const YUSD_TICKER = 'YUSD';
export const SPOOKY_ROUTER_ADDR = '0x60aE616a2155Ee3d9A68541Ba4544862310933d4';
export const ZAPPER_ROUTER_ADDR = '0x958d9686682214799a1F814648a7f15cfb77f1e3';
export const SWAPSICLE_ROUTER_ADDR = '0xC7f372c62238f6a5b79136A9e5D16A2FD7A3f0F5';
export const NRWL_ZAPPER_ADDR = '0xa95a898F5d32E41B5D6752ccAF65C3d59c4F2795';
export const TAX_OFFICE_ADDR = '0x';
